import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";

import qtum from "images/main/q-tum.png"
import ncloud24 from "images/main/ncloud24.png"
import lamate from "images/main/lamate.png"
import bridge from "images/main/bridge.png"
import qbao from "images/main/qbao.png"
import roundworm from "images/main/roundworm.png"
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  img: {
    width: '100%',
    maxWidth: '250px',
    display: "inline"
  },
  container: {
    display: "flex",
  },
  item: {
    flexGrow: 1,
    padding: theme.spacing(1),
  }
}));

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Arrow />,
  prevArrow: <Arrow />
};

const useStylesArrow = makeStyles((theme) => ({
  root: {
    '&::before': {
      color: "#000000"
    }
  },

}));

function Arrow(props) {
  const { className, style, onClick } = props;
  const classes = useStylesArrow();
  return (
    <div
      className={clsx(className, classes.root)}
      style={{ ...style, display: "block", color: "black" ,}}
      onClick={onClick}
    />
  );
}

const MainSection04 = () => {
  const classes = useStyles();
  return <div className={classes.root}>
    <Slider {...settings} style={{textAlign: "center"}}>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <p>
              <img className={classes.img} alt="qtum" src={qtum} style={{display: "inline"}}/>
            </p>
            <p>
              Often called as “Chinese Ethereum” that open<br/>
              blockchain project created by QTUM Foundation.<br/>
              It has advantage of both Bitcoin and Ethereum.<br/>
            </p>
          </Grid>
          <Grid item  xs={12} sm={6} lg={4}>
            <p>
              <img className={classes.img} alt="ncloud24" src={ncloud24} style={{display: "inline"}}/>
            </p>
            <p>
              Partnership with WellData System with Clour Server,<br/>
              KT Ucloud Biz, AWS, Naver Cloud, NCP, <br/>
              and PHOTON game engine.<br/>
            </p>
          </Grid>
          <Grid item  xs={12} sm={6} lg={4}>
            <p>
              <img className={classes.img} alt="lamate" src={lamate} style={{display: "inline"}}/>
            </p>
            <p>
              Game Publishing Company.<br/>
              Branch of &#8220;Cosmos Infra&#8221; whom globally <br/>
              published FPS game &#8220;Special Force&#8221;.<br/>
            </p>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <p>
              <img className={classes.img} alt="qtum" src={bridge} style={{display: "inline"}}/>
            </p>
            <p>
              Various creators from platforms like Bigo Live,<br/>
              YYTV, Panda TV, and other<br/>
              Chinese streaming services.<br/>
            </p>
          </Grid>
          <Grid item  xs={12} sm={6} lg={4}>
            <p>
              <img className={classes.img} alt="qbao" src={qbao} style={{display: "inline"}}/>
            </p>
            <p>
              The platform that everyone can buy with<br/>
              cryptocurrencies like QTUM, Bitcoin, and Ethereum.<br/>
            </p>
          </Grid>
          <Grid item  xs={12} sm={6} lg={4}>
            <p>
              <img className={classes.img} alt="roundworm" src={roundworm} style={{display: "inline"}}/>
            </p>
            <p>
              GA Mobile Reward Application Service company:<br/>
              A Reward Service Platform that provides advertisement<br/>
              into smartphone lock screens.<br/>
            </p>
          </Grid>
        </Grid>
      </div>
    </Slider>
  </div>;
};

export default MainSection04;
