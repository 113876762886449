import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import logoGray from "images/footer/logo_gray.png"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0a0a0a",
    minHeight: "30vh",
    [theme.breakpoints.down("xs")]: {
      minHeight: "20vh",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectBox: {
      minWidth: '200px'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
}));

const handleChange = (e) => {
  if(!e.target.value) return;
  window.open(e.target.value);
}

const MainFooter = () => {
  const classes = useStyles();
  return <div className={classes.root}>
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="center"
      style={{borderBottom: "1px solid rgba(255,255,255,0.13)", padding: "2vh", minHeight: "15vh"}}
    >
      <Grid xs={12} sm={4} lg={4} style={{textAlign: "center"}}>
        <img src={logoGray}/>
      </Grid>
      {/*<Grid item xs={12} sm={4} lg={4} style={{textAlign: "center"}}>
        <div>
          <a href="https://www.facebook.com/playcoin.game/">
            <img src="https://www.mcngamehub.com/wp-content/uploads/2018/08/sns-1.jpg" />
          </a>
          <a href="https://twitter.com/PlayCoin_PLY">
            <img src="https://www.mcngamehub.com/wp-content/uploads/2018/08/sns-2.jpg" />
          </a>
          <a href="https://weibo.com/playcoinnews">
            <img src="https://www.mcngamehub.com/wp-content/uploads/2018/08/sns-1.png" />
          </a>
          <a href="https://www.youtube.com/channel/UCEBqfSmg_KpD0lLWXOnNAbw/featured?disable_polymer=1">
            <img src="https://www.mcngamehub.com/wp-content/uploads/2018/08/sns-2.png" />
          </a>
        </div>
      </Grid>*/}
      <Grid item xs={12} sm={4} lg={4} style={{textAlign: "center"}}>
        <FormControl variant="outlined" className={classes.formControl} fullWidth={true} style={{backgroundColor: "white"}}>
          <InputLabel id="familySite">Family Site</InputLabel>
          <Select
            labelId="familySite"
            id="familySite"
            value=""
            className={classes.selectEmpty}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Family Site</em>
            </MenuItem>
            <MenuItem value={"https://www.playx.info"} >PlayX</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    <Grid container
          direction="row"
          justify="center"
          alignItems="center"
          >
      <Grid item p={2} style={{color: "white", padding: "25px"}}>
      Copyright© GameHub All rights reserved
      </Grid>
    </Grid>
  </div>;
};

export default MainFooter;
