import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";

import Image1 from "images/main/b1.png"
import Image2 from "images/main/b2.png"
import Image3 from "images/main/b3.png"
import Image4 from "images/main/b4.png"
import Image5 from "images/main/b5.png"
import Image6 from "images/main/b6.png"
import Image7 from "images/main/gbes.png"

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(5)
  },
  img: {
    width: '100%',
    maxWidth: '550px',
  },
  contentTitle:{
    color: "#44266e",
    fontWeight: "800",
    fontSize: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    }
  },
  contentSubTitle:{
    color: "#808080",
    fontWeight: "600",
    fontSize: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    }
  },
  contentDesc:{
    fontSize: 15,
  }
}));

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <Arrow />,
  prevArrow: <Arrow />
};

const useStylesArrow = makeStyles((theme) => ({
  root: {
    '&::before': {
      color: "#000000"
    }
  },

}));
function Arrow(props) {
  const { className, style, onClick } = props;
  const classes = useStylesArrow();
  return (
    <div
      className={clsx(className, classes.root)}
      style={{ ...style, display: "block", color: "black" ,}}
      onClick={onClick}
    />
  );
}

const MainSection02 = () => {
  const classes = useStyles();
  return <div className={classes.root}>
    <Slider {...settings}>
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} container direction="row" justify="flex-end">
            <img className={classes.img} alt="" src={Image7} />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column" justify="center">
            <div className={classes.contentTitle}>
              GBES
            </div>
            <div className={classes.contentSubTitle}>
              Gamehub Blockchain Enterprise Solution
            </div>
            <div className={classes.contentDesc}>
              <p>
                The most crucial factor that an enterprise should consider in introducing blockchain is the continuity of its business.
              </p>
              <p>
                Much more than a basic framework, the blockchain platform provided by GBES(Gamehub Blockchain Enterprise Solution) proposes a system that takes both business continuity and scalability and inti consideration and provides a stable operating environment.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      {/*<div>
        <Grid container>
          <Grid item xs={12} sm={6} container direction="row" justify="flex-end">
            <img className={classes.img} alt="" src={Image1} />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column" justify="center">
            <div className={classes.contentTitle}>
              Many Contents in the world,
            </div>
            <div className={classes.contentSubTitle}>
              The world has creator and its gamers.
            </div>
            <div className={classes.contentDesc}>
              <p>GameHub Ecosystem is</p>
              <p>created to make great contents culture</p>
              <p>that everybody would enjoy.</p>
            </div>
          </Grid>
        </Grid>
      </div>*/}
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} container direction="row" justify="flex-end">
            <img className={classes.img} alt="" src={Image2} />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column" justify="center">
            <div className={classes.contentTitle}>
              PlayGame
            </div>
            <div className={classes.contentSubTitle}>
              The quickest platform Global Publishing
            </div>
            <div className={classes.contentDesc}>
              <p>
                <span>GameHub is collaborating with Qbao, the Chinese social network</span><br />
                <span>service, to provide stable profitable structure for Single-Developer, indie</span><br />
                <span>game companies, and small game companies.</span>
              </p>
              <p>
                <span>Qbao is multi-social network platform based on public chain that has</span><br />
                <span>digital wallet, payment gateway, chatting, emojis, and various themes.</span><br />
                <span>The game contents will be provided to the users and gamers in Qbao</span><br />
                <span>Network</span>
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} container direction="row" justify="flex-end">
            <img className={classes.img} alt="" src={Image3} />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column" justify="center">
            <div className={classes.contentTitle}>
              PlayWallet
            </div>
            <div className={classes.contentSubTitle}>
              Blockchain Cryptocurrency Wallet
            </div>
            <div className={classes.contentDesc}>
              <p>
                <span>PlayWallet is the blockchain cryptocurrency wallet that GameHub created.</span>
              </p>
              <p>
                <span>PlayWallet has all features such as store, payment, send and receive</span><br />
                <span>of cryptocurrencies. Also, this allows users to purchase contents,</span><br />
                <span>games, and items.</span>
              </p>
              <p>
                <span>PlayWallet will be most convenient system that supports every</span><br />
                <span>entertainment platform such as movie, music, arcades, and others.</span>
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} container direction="row" justify="flex-end">
            <img className={classes.img} alt="" src={Image4} />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column" justify="center">
            <div className={classes.contentTitle}>
              PlayShop
            </div>
            <div className={classes.contentSubTitle}>
              “Live Better”, Easy Coin Payment Service
            </div>
            <div className={classes.contentDesc}>
              <p>
                <span>UnionWallet is the blockchain cryptocurrency wallet that GameHub created.</span>
              </p>
              <p>
                <span>PlayShop is the coupon shop that an be purchased with PlayCoin. </span><br />
                <span>You can buy anything anytime with PlayCoin. No credit card, no cash NEEDED!</span></p>
              <p><span>With PlayShop, you may enjoy luxurious cultures.</span><br />
                <span>Games, movies, music, coffee, concert, and other digital contents can</span><br />
                <span>be purchased with safe platform.</span>
              </p>
              <p>
                <strong>
                  <span>PlayShop will play relevant roles<br />
                  in our lives that shoaws “Live Better”.</span>
                </strong>
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} container direction="row" justify="flex-end">
            <img className={classes.img} alt="" src={Image5} />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column" justify="center">
            <div className={classes.contentTitle}>
              CoinWeather
            </div>
            <div className={classes.contentSubTitle}>
              Real time AI cryptocurrency market tracker:
            </div>
            <div className={classes.contentDesc}>
              <p>
                <span>CoinWeather is the AI analyzing service for cryptocurrency investors.</span><br />
                <span>This gathers all information of cryptocurrency to provide precious data to the</span><br />
                <span>investors that promotes better investing.</span>
              </p>
              <p>
                <span>Advantage of CoinWeather is to summarize tremendous amount of news and</span><br />
                <span>updates of cryptocurrency through Deep-Learning process.</span><br />
                <span>This will help you to invest cryptocurrency with best possible scenario.</span>
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      {/*<div>
        <Grid container>
          <Grid item xs={12} sm={6} container direction="row" justify="flex-end">
            <img className={classes.img} alt="" src={Image6} />
          </Grid>
          <Grid item xs={12} sm={6} container direction="column" justify="center">
            <div className={classes.contentTitle}>
              IP Biz
            </div>
            <div className={classes.contentSubTitle}>
              Variety of Game Market with new contents
            </div>
            <div className={classes.contentDesc}>
              <p>
                <span>GameHub pursues happiness of contents developers and game users.</span><br />
                <span>To pursue this, GameHub is launching the IP business to develop new</span><br />
                <span>and better contents that fits into current game trend. Through this,</span><br />
                <span>even old-fashioned games can be revived with IP business.</span>
              </p>
              <p>
                <span>As a starter, GameHub will launch the well-known game called “Zwei!!”.</span><br />
                <span>Not only that, GameHub will launch more contents that everyone loved.</span>
              </p>
              <p>
                <span>Cultural Contents Market that respects variety of genre:</span><br />
                <span>GameHub will open up with better IP that will create better game</span><br />
                <span>market that people will love.</span>
              </p>
            </div>
          </Grid>
        </Grid>
      </div>*/}

    </Slider>
  </div>;
};

export default MainSection02;
