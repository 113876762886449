import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Appbar from "components/Appbar";
import MainSection01 from "components/MainSection01";
import MainSection02 from "components/MainSection02";
import MainSection03 from "components/MainSection03";
import MainSection04 from "components/MainSection04";
import MainSection04Mobile from "components/MainSection04Mobile";
import MainContactus from "components/MainContactus";
import MainFooter from "components/MainFooter";


const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    marginTop: theme.spacing(12),
  },
}));
function App() {
  const classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <div className="App">
      <Appbar />
      <div className={classes.content}>
        <div id="">
          <MainSection01 />
        </div>
        <div id="business">
          <MainSection02 />
        </div>
        <div>
          <MainSection03 />
        </div>
        <div id="partners">
          {/*{matches ? <MainSection04Mobile /> :<MainSection04 />}*/}
          <MainSection04 />
        </div>
        <div id="contactus">
          <MainContactus />
        </div>
      </div>
      <footer>
        <MainFooter />
      </footer>
    </div>
  );
}

export default App;
