import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Logo from "images/main/main_logo.png"
import MainText from "images/main/main_text.png"
import MainBackImg from "images/main/main_img.png"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(10),
    width: "100%",
    minHeight: "50vh",
    [theme.breakpoints.down("xs")]: {
      minHeight: "50vh",
    },
    backgroundImage: `url(${MainBackImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column"
  },
  main: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    width: '40%',
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: '700px',
      minWidth: '250px',
    },
  },
  mainLogoImg: {
    width: '30%',
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: '600px',
      minWidth: '220px'
    },
  },
}));

const MainSection01 = () => {
  const classes = useStyles();
  return <div className={classes.root}>
    <div className={classes.main}>
      <img className={classes.img} src={MainText} />
    </div>
    <br/>
    <div className={classes.main}>
      <img className={classes.mainLogoImg} src={Logo} />
    </div>
  </div>;
};

export default MainSection01;
