import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(2),
    width: "100%",
    minHeight: "30vh",
  },
  gamehub:{
    color: "#44266e",
    fontWeight: "600",
    fontSize: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
    }
  },
}));

const MainContactus = () => {
  const classes = useStyles();
  return <div className={classes.root}>
    {/*<div>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.7996270098208!2d114.17825267178648!3d22.299397351598216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ef70fc03ad%3A0xcad0bd32ef71ce72!2sSouth+Seas+Centre%2C+75+Mody+Rd%2C+Tsim+Sha+Tsui+East%2C+%ED%99%8D%EC%BD%A9!5e0!3m2!1sko!2skr!4v1530678415151"
            width="100%" height="450" frameBorder="0" allowFullScreen="allowfullscreen" data-mce-fragment="1" />
    </div>*/}
    <div style={{textAlign: "center"}}>
      <p className={classes.gamehub}>GAMEHUB KOREA</p>
      <p>
        Unit 706, 7/F, South Seas Centre, Tower 2,<br />
        75 Mody Road, TsimShaTsui, Hong Kong.
      </p>
      <p>
        Tel : +852 8191 5140 ㅣ Email : support@mcngamehub.com
      </p>
      <p>GameHub Korea</p>
    </div>
  </div>;
};

export default MainContactus;
