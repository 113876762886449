import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f7f7f7",
    display: "flex",
    padding: theme.spacing(2),
    width: "100%",
    minHeight: "100vh",
  },
}));

const MainSection04Mobile = () => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return <div className={classes.root}>Partners Mobile</div>;
};

export default MainSection04Mobile;
