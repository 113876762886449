import React, { useEffect, useState, useRef } from "react";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import "scroll-behavior-polyfill";
import smoothscroll from "smoothscroll-polyfill";

import HeaderLogo from "../images/header_logo.png"


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    // height: theme.spacing(10),
    background: "transparent",
    // background: "#ffffff",
    boxShadow: "none",
    backgroundColor: "rgba(51, 51, 51, 0)",
    borderColor: "rgba(255,255,255,.1)",
    borderBottom: "1px solid rgba(255,255,255,.1)",
    minHeight: theme.spacing(12),

    width: "100%",
  },
  appBarScroll: {
    background: "#f1f1f1",
  },
  toolBar: {
    // height: theme.spacing(10),
    minHeight: 90,
    // paddingRight: 0,
    width: "100%",
    maxWidth: 960,
    marginLeft: "auto",
    marginRight: "auto",
  },
  menuButtonWrap: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      borderLeft: "1px solid rgba(255,255,255,.1)",
      height: "100%",
      width: 80,
      justifyContent: "center",
      justifyItems: "center",
    },
  },
  menuButton: {
    margin: "auto",
  },
  titleWrap: {
    display: "flex",
    flexGrow: 1,
    textAlign: "center",
    color: "#ffffff",
    justifyItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  titleButtonWrap: {
    position: "relative",
    cursor: "pointer",

    transition: "0.5s",
    "&:before": {
      content: `""`,
      position: "absolute",
      display: "block",
      top: "50%",
      marginTop: 15,
      width: "0rem",
      height: 2,
      background: "#ffffff",
      "-webkit-transition": "all 320ms ease",
      "-moz-transition": "all 320ms ease",
      "-ms-transition": "all 320ms ease",
      "-o-transition": "all 320ms ease",
      transition: "all 320ms ease",
    },
    "&:hover:before": {
      content: `""`,
      position: "absolute",
      display: "block",
      top: "50%",
      marginTop: 15,
      width: "1.4285714286rem",
      height: 2,
      background: "#ffffff",
      // left: 0
    },
  },
  titleButtonWrapActive: {
    "&::after": {
      // content: '"some content"',
      // left: "1.7857142857rem",
      "-webkit-transform": "scale3d(1,1,1)",
      transform: "scale3d(1,1,1)",
      "-webkit-transform-origin": "0 50%",
      "transform-origin": "0 50%",
      "-webkit-transition-timing-function": "ease",
      "transition-timing-function": "ease",
      transition: "all 320ms ease",
      background: "#ffffff",
      content: `""`,
      position: "absolute",
      display: "block",
      top: "50%",
      marginTop: 15,
      width: "1.4285714286rem",
      height: 2,
      left: 0,
    },
  },
  titleButton: {
    color: "#111111",
    fontSize: 15,
    fontStyle: "normal",
    fontWeight: "bold",
    // fontFamily: "Poppins",
    textTransform: "none",
    marginRight: 50,
  },
  logoWrap: {
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      flex: 1,
    },
  },
  drawerPaper: {
    // marginTop: theme.spacing(10)
  },
  poperPaper: {
    width: "90vw",
    marginTop: theme.spacing(3),
    // zIndex: 99
  },
  logoImage: {
    maxWidth: 150,
  },
  // grow: {
  //   zIndex: 99
  // },
  // popper:{
  //   zIndex: 99
  // }
}));

const Nav = ({ window }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [over, setOver] = useState(false);
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  const toggleDrawer = (open) => (event) => {
    setOpenDrawer(open);
  };

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (target) => {
    const anchor = document.querySelector(`#${target}`);

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleClickMobile = (target) => {
    const anchor = document.querySelector(`#${target}`);
    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      // setOpen(() => {
      //   const result = anchor.scrollIntoView({
      //     behavior: "smooth",
      //     block: "start"
      //   });

      // });
    }
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root} id="home">
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, trigger && classes.appBarScroll)}
      >
        <Toolbar className={classes.toolBar}>
          <div
            className={classes.logoWrap}
            onClick={() => handleClick("home")}
            role="presentation"
          >
            <img
              class="preload-me"
              src={HeaderLogo}
              srcset={HeaderLogo}
              width="56"
              height="30"
              sizes="56px"
              alt="게임허브"
            />
          </div>

          <div className={classes.titleWrap}>
            <div
              className={classes.titleButtonWrap}
              onClick={() => handleClick("home")}
              role="presentation"
            >
              <div className={classes.titleButton}>Home</div>
            </div>
            <div
              className={classes.titleButtonWrap}
              onClick={() => handleClick("business")}
              role="presentation"
            >
              <div className={classes.titleButton}>Business</div>
            </div>
            {/*<div
                className={classes.titleButtonWrap}
                onClick={() => handleClick("technology")}
                role="presentation"
            >
              <div className={classes.titleButton}>Technology</div>
            </div>*/}
            <div
              className={classes.titleButtonWrap}
              onClick={() => handleClick("partners")}
              role="presentation"
            >
              <div className={classes.titleButton}>Partners</div>
            </div>

            <div
              className={classes.titleButtonWrap}
              onClick={() => handleClick("contactus")}
              role="presentation"
            >
              <div className={classes.titleButton}>Contact Us</div>
            </div>
          </div>
          <div className={classes.menuButtonWrap}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              // onClick={toggleDrawer(true)}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              {over ? <KeyboardArrowDownIcon /> : <MenuIcon />}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        // disablePortal
        placement="bottom-end"
        // keepMounted
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={classes.poperPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  // onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={() => handleClickMobile("home")}>
                    Home
                  </MenuItem>
                  <MenuItem onClick={() => handleClickMobile("business")}>
                    Business
                  </MenuItem>
                  <MenuItem onClick={() => handleClickMobile("partners")}>
                    Partners
                  </MenuItem>
                  <MenuItem onClick={() => handleClickMobile("contactus")}>
                    Contact Us
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {/* <Drawer
        open={openDrawer}
        onClose={toggleDrawer(false)}
        anchor="top"
        // variant="persistent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <SideList toggleDrawer={toggleDrawer} />
      </Drawer> */}
    </div>
  );
};

export default Nav;
