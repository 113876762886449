import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BackImg from "../images/main/sbg.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(10),
    width: "100%",
    minHeight: "40vh",
    [theme.breakpoints.down("xs")]: {
      minHeight: "50vh",
    },
    backgroundImage: `url(${BackImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column"
  },
  content: {
    fontSize: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    textAlign: "center",
  },
}));

const MainSection03 = () => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return <div className={classes.root}>
    <div className={classes.content}>
      ❝ GAMEHUB creates<br/>
    Innovative Crypto Manufacturing, <br/>
    Supply Chain, Finance Platforms<br/>
    With proprietary blockchain technology. ❞
    </div>
  </div>;
};

export default MainSection03;
